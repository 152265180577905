import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default function addProduct() {
  const distance = ref('')
  const slug = ref('')
  const imgID = ref(null)
  const category = ref(null)
  const status = ref(null)
  const isActive = ref(true)
  const arrayPosts = [
    {
      title: '',
      languageCode: '',
      subDescription: '',
      description: '',
      distance: '',
    },
  ]
  const countPost = ref(1)
  const idPost = ref(null)
  const loading = ref(false)
  const toast = useToast()

  // Fetch post
  if (router.currentRoute.params.id) {
    loading.value = true
    idPost.value = router.currentRoute.params.id
    store
      .dispatch('posts/fetchPost', { id: idPost.value })
      .then(response => {
        console.log(response)
        loading.value = false
      }).catch(e => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: e.response.data.error,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        loading.value = false
      })
  } else {
    loading.value = true
    store.dispatch('posts/fetchCategories').then(() => {
      loading.value = false
    }).catch(e => {
      console.log(e)
      toast({
        component: ToastificationContent,
        props: {
          title: e.response.data.error,
          icon: 'BellIcon',
          variant: 'danger',
        },
      })
      loading.value = false
    })
  }
  return {
    status,
    imgID,
    category,
    arrayPosts,
    countPost,
    distance,
    slug,
    loading,
    isActive,
  }
}
